import { StyleSheet, Platform } from 'react-native'
import COLORS from '../../utils/colors'
import { BuilderTheme } from '../ThemeProvider/themes'

const getStyles = (theme: BuilderTheme) => StyleSheet.create({
    container: {
        borderWidth: theme?.spacing?.borderWidth ?? 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme?.colors?.background ?? 'transparent',
        borderRadius: theme?.spacing?.borderRadius ?? 10,
        width: '100%',
        padding: theme?.spacing?.padding ?? 8,
    },
    input: {
        fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
        fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
        color: COLORS.COLOR_COOLGRAY,
        width: '100%',
        zIndex: 1,
        marginLeft: theme?.spacing?.padding ?? 4,
        ...Platform.select({
            web: { outlineStyle: 'none' },
        }),
    },
    error: {
        fontSize: theme?.typography?.size?.['2xs']?.fontSize ?? 10,
        color: theme?.colors?.error ?? COLORS.COLOR_RED,
        fontFamily: theme?.typography?.fontFamily ?? 'Arial',
        marginTop: theme?.spacing?.margin ?? 4,
        marginLeft: theme?.spacing?.margin ?? 10,
    },
    fixes: {
        paddingLeft: theme?.spacing?.padding ?? 6,
        paddingRight: theme?.spacing?.padding ?? 6,
        fontWeight: theme?.typography?.fontWeight?.bold ?? '700',
        fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
        color: COLORS.COLOR_GRAY,
        fontFamily: theme?.typography?.fontFamily ?? 'Arial',
    },
    label: {
        fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
        fontWeight: theme?.typography?.fontWeight?.bold ?? '700',
        color: theme?.colors?.primary ?? '#334155',
    },
    labelContainer: {
        marginBottom: theme?.spacing?.margin ?? 8,
    }
})

export default getStyles
