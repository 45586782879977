import { StyleSheet } from 'react-native';
import { BuilderTheme } from '../ThemeProvider/themes';

const hs = (value: number) => value;
const ws = (value: number) => value;
const ms = (value: number) => value;

export const makeStyles = (theme: BuilderTheme) =>
  StyleSheet.create({
    container: {
      display: 'flex',
    },
    placeHolderContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderWidth: ms(theme?.spacing?.borderWidth || 0),
      borderColor: theme?.colors?.border || '#1B1B3A',
      borderTopLeftRadius: ms(theme?.spacing?.borderRadius || 8),
      borderTopRightRadius: ms(theme?.spacing?.borderRadius || 8),
      paddingHorizontal: ws(theme?.spacing?.padding || 16),
    },
    contentContainer: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      borderBottomLeftRadius: ms(theme?.spacing?.borderRadius || 8),
      borderBottomRightRadius: ms(theme?.spacing?.borderRadius || 8),
      backgroundColor: theme?.colors?.background || 'transparent',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      borderRadius: ms(theme?.spacing?.borderRadius || 0),
      backgroundColor: theme?.colors?.background || 'transparent',
      marginTop: hs(2),
      padding: ws(theme?.spacing?.padding || 16),
      alignItems: 'center',
    },
    selectedListContainer: {
      backgroundColor: theme?.colors?.secondaryBackground || '#E1CCFF',
    },
    placeholderTextStyle: {
      fontSize: ms(theme?.typography?.size?.lg?.fontSize || 18),
      fontWeight: theme?.typography?.fontWeight?.normal || '300',
      color: theme?.colors?.text || 'black',
    },
    placeholderLabelTextStyle: {
      fontSize: ms(theme?.typography?.size?.lg?.fontSize || 18),
      fontWeight: theme?.typography?.fontWeight?.normal || '300',
      color: theme?.colors?.text || 'black',
    },
    dropdownContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: 'center',
    },
    imageContainer: {
      display: 'flex',
      overflow: 'hidden',
      alignSelf: 'center',
    },
    iconContainer: {
      display: 'flex',
      overflow: 'hidden',
      marginRight: hs(theme?.spacing?.margin || 8),
    },
    image: {
      height: hs(20),
      width: hs(20),
    },
  });
