import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'
import { BuilderTheme } from '../ThemeProvider/themes'

export const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    loaderContainer: {
        height: 10,
        width: '100%',
        backgroundColor: theme?.colors?.background || COLORS.COLOR_WHITE,
        borderRadius: theme?.spacing?.borderRadius || 5,
        overflow: 'hidden',
    },
    loaderSmallContainer: {
        height: 10,
        width: '30%',
        backgroundColor: theme?.colors?.secondaryBackground || COLORS.COLOR_LIGHTGRAY,
        borderRadius: theme?.spacing?.borderRadius || 5,
        overflow: 'hidden',
    },
    loaderProgress: {
        height: '100%',
        backgroundColor: theme?.colors?.primary || COLORS.COLOR_PURPLE,
    },
    progressSpinner: {
        borderWidth: theme?.spacing?.borderWidth || 8,
        borderColor: theme?.colors?.hover || COLORS.COLOR_HIGHLIGHTBLUE,
        borderTopWidth: theme?.spacing?.borderWidth || 8,
        borderTopColor: theme?.colors?.error || COLORS.COLOR_BLUE,
        borderRadius: 40,
        width: 80,
        height: 80,
    },
    progressDarkSpinner: {
        borderWidth: theme?.spacing?.borderWidth || 8,
        borderColor: theme?.colors?.disabled || COLORS.COLOR_WHITE,
        borderTopWidth: theme?.spacing?.borderWidth || 8,
        borderTopColor: theme?.colors?.border || COLORS.COLOR_GRAY,
        borderRadius: 40,
        width: 80,
        height: 80,
    },
    progressMainContainer: {
        backgroundColor: theme?.colors?.background || COLORS.COLOR_WHITE,
        alignItems: 'center',
        justifyContent: 'center',
        width: 300,
    },
    progressDarkThemeMainContainer: {
        backgroundColor: theme?.colors?.background || COLORS.COLOR_BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        width: 300,
    },
})
