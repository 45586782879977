import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'
import { BuilderTheme } from '../ThemeProvider/themes'

const createStyles = (theme: BuilderTheme) => StyleSheet.create({
    calendarStyle: {
        marginTop: 2,
        backgroundColor: theme?.colors?.background ?? COLORS.COLOR_WHITE,
        minHeight: 350,
        shadowColor: theme?.colors?.shadow ?? COLORS.COLOR_BLACK,
        maxWidth: 400,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        borderRadius: theme?.spacing?.borderRadius ?? 8,
    },
    imgStyle: {
        height: '100%',
        width: '100%',
        resizeMode: 'stretch',
    },
    renderArrowStyle: { height: 20, width: 20 },
    container: {
        borderWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        backgroundColor: theme?.colors?.background ?? COLORS.COLOR_WHITE,
        borderRadius: 8,
        borderColor: theme?.colors?.border ?? COLORS.COLOR_MEDIUMBLUE,
        height: 40,
    },
    textInputStyle: {
        fontSize: theme?.typography?.size?.base?.fontSize ?? 16,
        fontWeight: theme?.typography?.fontWeight?.normal ?? '400',
        padding: theme?.spacing?.padding ?? 10,
        minWidth: 200,
        backgroundColor: theme?.colors?.background ?? 'transparent',
        color: theme?.colors?.text ?? COLORS.COLOR_GRAY
    },
    leftIcon: {
        marginLeft: theme?.spacing?.margin ?? 8,
        color: theme?.colors?.text ?? COLORS.COLOR_GRAY,
        height: 18,
        width: 18,
        textAlign: 'center',
    },
});

export default createStyles;

