import { StyleSheet } from 'react-native';
import { BuilderTheme } from '../../ThemeProvider/themes';

const createStyles = (theme?: BuilderTheme) => StyleSheet.create({
    container: {
        borderBottomColor: theme?.colors?.border ?? '#E2E8F0',
        borderBottomWidth: theme?.spacing?.borderWidth ?? 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: theme?.spacing?.padding ?? 20,
        flexWrap: 'wrap',
        alignSelf: 'stretch',
        display: 'flex',
    },
    headerLogoTextStyle: {
        color: theme?.colors?.primary ?? '#334155',
        fontSize: theme?.typography?.size?.xl?.fontSize ?? 20,
        marginHorizontal: theme?.spacing?.margin ?? 10,
    },
    rightIconStyle: {
        paddingHorizontal: 0,
    },
    flexStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
    },
    flexEndStyle: {
        justifyContent: 'flex-end',
    },
    navButtonNameStyle: {
        color: theme?.colors?.primary ?? '#334155',
        fontSize: theme?.typography?.size?.xs?.fontSize ?? 12,
        paddingHorizontal: theme?.spacing?.padding ?? 4,
    },
    inputStyle: {
        borderRadius: 100,
        minWidth: 360,
    },
    hamburgerStyle: {
        width: 19.5,
        height: 13,
        paddingRight: theme?.spacing?.padding ?? 10,
        color: theme?.colors?.text ?? 'black',
        marginHorizontal: theme?.spacing?.margin ?? 10,
    },
    drawerContainer: {
        position: 'absolute',
        zIndex: 10,
        bottom: 0,
        top: 350,
        backgroundColor: theme?.colors?.background ?? 'red',
        width: 10,
        height: '100%',
    },
    desktopPadding: {
        paddingHorizontal: theme?.spacing?.padding ?? 5,
    },
});

export default createStyles;
