import React from 'react'
import { ActivityIndicator, Text, View } from 'react-native'
import { makeRNCompatibleStyleArr } from '../../utils/makeRNCompatibleStyleArr'
import COLORS from '../../utils/colors'
import { createStyles } from './styles'
import { ButtonProps } from './types'
import { useTheme } from '../ThemeProvider/ThemeProvider'

export const ButtonBase = (props: ButtonProps) => {
    const {
        loading,
        text,
        textStyle,
        icon,
        iconPosition,
        testID,
        activityIndicatorColor,
    } = props

    const { theme } = useTheme()
    const styles = createStyles(theme)

    const finalTextStyle = makeRNCompatibleStyleArr([
        styles.text,
        { opacity: loading ? 0.44 : 1 },
        textStyle,
    ])

    const activityIndicatorTestID = testID
        ? `${testID}-activityIndicator`
        : 'activityIndicator'

    return (
        <>
            {text && <Text style={finalTextStyle}>{text}</Text>}
            {(icon || loading) && (
                <View
                    style={{
                        marginLeft:
                            iconPosition !== 'left' && text
                                ? styles.icon.marginHorizontal
                                : 0,
                        marginRight:
                            iconPosition === 'left' && text
                                ? styles.icon.marginHorizontal
                                : 0,
                    }}
                >
                    {loading ? (
                        <ActivityIndicator
                            testID={activityIndicatorTestID}
                            color={
                                activityIndicatorColor || COLORS.COLOR_LIGHTPINK
                            }
                            style={styles.activityIndicator}
                        />
                    ) : (
                        icon
                    )}
                </View>
            )}
        </>
    )
}
