export interface BuilderTheme {
    colors: {
        primary: string;
        secondary: string;
        background: string;
        secondaryBackground: string;
        text: string;
        border: string;
        hover: string;
        disabled: string;
        shadow: string;
        loading: string;
        error: string;
        warning: string;
        info: string;
    };
    typography: {
        fontFamily: string;
        fontWeight: {
            normal: "normal" | "bold" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900",
            bold: "normal" | "bold" | "100" | "200" | "300" | "400" | "500" | "600" | "700" | "800" | "900"
        },
        letterSpacing: number;
        size: {
            '3xs': {
                fontSize: number;
                lineHeight: number;
            },
            '2xs': {
                fontSize: number;
                lineHeight: number;
            },
            xs: {
                fontSize: number;
                lineHeight: number;
            },
            sm: {
                fontSize: number;
                lineHeight: number;
            },
            base: {
                fontSize: number;
                lineHeight: number;
            },
            lg: {
                fontSize: number;
                lineHeight: number;
            },
            xl: {
                fontSize: number;
                lineHeight: number;
            },
            '2xl': {
                fontSize: number;
                lineHeight: number;
            },
            '3xl': {
                fontSize: number;
                lineHeight: number;
            },
            '4xl': {
                fontSize: number;
                lineHeight: number;
            },
            '5xl': {
                fontSize: number;
                lineHeight: number;
            },
        }
    };
    spacing: {
        padding: number;
        margin: number;
        borderRadius: number;
        borderWidth: number;
    };
    icons: {
        size: number;
        color: string;
    };
}

const lightTheme: BuilderTheme = {
    colors: {
        primary: '#1976d2',
        secondary: '#424242',
        background: '#ffffff',
        secondaryBackground: '#F0E5FF',
        text: '#000000',
        border: '#e0e0e0',
        hover: '#1565c0',
        disabled: '#bdbdbd',
        shadow: '#171717',
        loading: '#F1F5F9',
        error: '#b00020',
        warning: 'yellow',
        info: 'lightgray'
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        letterSpacing: 0.5,
        fontWeight: {
            normal: 'normal',
            bold: '700'
        },
        size: {
            '3xs': {
                fontSize: 8,
                lineHeight: 14
            },
            '2xs': {
                fontSize: 10,
                lineHeight: 16
            },
            xs: {
                fontSize: 12,
                lineHeight: 18,
            },
            sm: {
                fontSize: 14,
                lineHeight: 22,
            },
            base: {
                fontSize: 16,
                lineHeight: 24,
            },
            lg: {
                fontSize: 18,
                lineHeight: 26,
            },
            xl: {
                fontSize: 20,
                lineHeight: 28,
            },
            '2xl': {
                fontSize: 24,
                lineHeight: 32,
            },
            '3xl': {
                fontSize: 30,
                lineHeight: 40,
            },
            '4xl': {
                fontSize: 36,
                lineHeight: 44,
            },
            '5xl': {
                fontSize: 48,
                lineHeight: 56,
            }
        }
    },
    spacing: {
        padding: 8,
        margin: 8,
        borderRadius: 8,
        borderWidth: 1,
    },
    icons: {
        size: 24,
        color: '#000000',
    },
};

const darkTheme: BuilderTheme = {
    ...lightTheme,
    colors: {
        ...lightTheme.colors,
        background: '#121212',
        text: '#ffffff',
        border: '#424242',
    },
};

// Export the themes
export const themes = {
    light: lightTheme,
    dark: darkTheme,
};

// Utility function to merge themes
export const mergeThemes = (customTheme?: BuilderTheme, defaultTheme: BuilderTheme = lightTheme): BuilderTheme => {
    return {
        ...defaultTheme,
        ...customTheme,
        colors: { ...defaultTheme.colors, ...(customTheme?.colors || {}) },
        typography: { ...defaultTheme.typography, ...(customTheme?.typography || {}) },
        spacing: { ...defaultTheme.spacing, ...(customTheme?.spacing || {}) },
        icons: { ...defaultTheme.icons, ...(customTheme?.icons || {}) },
    };
};
