import React, { useState } from 'react';
import { TextInput, View, Text, ViewStyle, TextStyle, TextInputProps } from 'react-native';
import getStyles from './styles';
import COLORS from '../../utils/colors';
import { Size } from '../../utils/constants';
import { useTheme } from '../ThemeProvider/ThemeProvider';

export interface InputProps extends TextInputProps {
    size?: Size;
    error?: string;
    suffix?: string;
    prefix?: string;
    label?: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    labelTextStyle?: TextStyle;
    labelContainerStyle?: ViewStyle;
    prefixStyle?: TextStyle;
    suffixStyle?: TextStyle;
    errorStyle?: TextStyle;
    textStyle?: TextStyle;
    containerStyle?: ViewStyle;
    borderColor?: string;
    borderHighlightColor?: string;
    borderErrorColor?: string;
    icon?: string;
}

const Input: React.FC<InputProps> = ({
    rightIcon,
    size,
    error,
    suffix,
    prefix,
    label,
    leftIcon,
    labelTextStyle,
    borderHighlightColor = COLORS.COLOR_HIGHLIGHTBLUE,
    borderColor = COLORS.COLOR_GREY_INPUT,
    borderErrorColor = COLORS.COLOR_ERROR,
    prefixStyle,
    suffixStyle,
    errorStyle,
    textStyle,
    containerStyle,
    icon,
    labelContainerStyle,
    ...props
}) => {
    // Access theme from the ThemeProvider using the useTheme hook
    const { theme } = useTheme();
    const styles = getStyles(theme);

    const [value, setValue] = useState<string | null>(null);
    const [inputBorderColor, setInputBorderColor] = useState<string>(borderColor);

    const onChangeText = (text: string) => {
        setValue(text);
        if (props.onChangeText) {
            props.onChangeText(text);
        }
    };

    const onFocus = () => {
        setInputBorderColor(borderHighlightColor);
    };

    const onBlur = () => {
        setInputBorderColor(borderColor);
    };

    const getSize = (size: Size = Size.Medium): number => {
        switch (size) {
            case Size.Small:
                return 32;
            case Size.Large:
                return 56;
            case Size.Medium:
            default:
                return 44;
        }
    };

    return (
        <>
            {!!label && (
                <View style={[styles.labelContainer, labelContainerStyle]}>
                    <Text style={[styles.label, labelTextStyle]}>
                        {label}
                    </Text>
                </View>
            )}
            <View
                style={[
                    styles.container,
                    {
                        borderColor: error ? borderErrorColor : inputBorderColor,
                        height: getSize(size),
                    },
                    containerStyle,
                ]}
            >
                {leftIcon || null}
                {!!prefix && (
                    <Text style={[styles.fixes, prefixStyle]}>
                        {prefix}
                    </Text>
                )}
                <TextInput
                    style={[
                        styles.input,
                        textStyle,
                    ]}
                    placeholderTextColor={borderColor}
                    onChangeText={onChangeText}
                    onBlur={(e) => {
                        onBlur()
                        props.onBlur && props.onBlur(e)
                    }}
                    onFocus={(e) => {
                        onFocus()
                        props.onFocus && props.onFocus(e)
                    }}
                    {...props}
                />
                {!!suffix && (
                    <Text style={[styles.fixes, suffixStyle]}>
                        {suffix}
                    </Text>
                )}
                {rightIcon || null}
            </View>
            {!!error && (
                <View>
                    <Text style={[styles.error, errorStyle]}>{error}</Text>
                </View>
            )}
        </>
    );
};

export default Input;
