import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import moment from 'moment';
import createStyles from './styles';
import { getMonthNames } from '../utils';
import { YearlyViewProps } from './interface';
import { useTheme } from '../../ThemeProvider/ThemeProvider';


const months = getMonthNames();

export const YearlyView: React.FC<YearlyViewProps> = ({
  dateInput,
  renderArrow,
  setView,
  view,
  selectedDate,
  setSelectedDate,
  selectedYear,
  setSelectedYear,
  setInputValue,
}) => {
  const { theme } = useTheme();
  const styles = createStyles(theme);
  const handleNavigateYear = useCallback(
    (direction) => {
      const newYear =
        direction === 'next' ? selectedYear + 1 : selectedYear - 1;
      setSelectedYear(newYear);
    },
    [selectedYear],
  );

  return (
    <View style={styles.container}>
      {dateInput}
      <View style={[styles.contentStyle]}>
        <View style={[styles.navigationHeader]}>
          <TouchableOpacity onPress={() => handleNavigateYear('prev')}>
            {renderArrow('left')}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setView(view === 'monthly' ? 'yearly' : 'monthly')}
          >
            <Text style={styles.headerTitle}>
              {selectedYear.toString()}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleNavigateYear('next')}>
            {renderArrow('right')}
          </TouchableOpacity>
        </View>
        <ScrollView
          style={styles.monthGridContainer}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.monthGrid}>
            {months.map((month, index) => {
              const isSelected =
                month === selectedDate.format('MMMM') &&
                selectedYear === selectedDate.year();

              return (
                <TouchableOpacity
                  key={month}
                  style={[
                    styles.monthButton,
                    isSelected && styles.selectedMonth,
                  ]}
                  onPress={() => {
                    const newDate = moment()
                      .year(selectedYear)
                      .month(index);
                    setSelectedDate(newDate);
                    setInputValue(newDate.format('MMM D, YYYY'));
                    setTimeout(() => {
                      setView('monthly');
                    }, 200);
                  }}
                >
                  <Text
                    style={[
                      styles.monthText,
                      isSelected && styles.selectedMonthText,
                    ]}
                  >
                    {month}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};
