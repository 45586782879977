import React from 'react'
import { Text as TextComp } from 'react-native'

/**
 * @deprecated Since version 1.1.4. Use Typography component instead.
 */
const Text = (props: any) => {
    return <TextComp {...props}>{props?.children}</TextComp>
}
export default Text
