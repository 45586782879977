import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Calendar as RNCalendar } from 'react-native-calendars';
import moment from 'moment';
import createStyles from './styles';
import { getMonthNames } from '../utils';
import { MonthlyViewProps } from './interface';
import { useTheme } from '../../ThemeProvider/ThemeProvider';
import COLORS from '../../../utils/colors';

const months = getMonthNames();

export const MonthlyView: React.FC<MonthlyViewProps> = ({
  dateInput,
  renderArrow,
  setView,
  view,
  selectedDate,
  setSelectedDate,
  handleOnDayPress,
  markedDates,
  theme: calendarTheme,
  ...props
}) => {
  const { theme } = useTheme();
  const styles = createStyles(theme);
  
  const handleNavigateMonth = useCallback(
    (direction) => {
      const newDate =
        direction === 'next'
          ? moment(selectedDate).add(1, 'months')
          : moment(selectedDate).subtract(1, 'months');
      setSelectedDate(newDate);
    },
    [selectedDate],
  );

  return (
    <View style={styles.container}>
      {dateInput}
      <RNCalendar
        renderArrow={renderArrow}
        customHeaderTitle={
          <TouchableOpacity
            onPress={() =>
              setView(view === 'monthly' ? 'yearly' : 'monthly')
            }
          >
            <Text style={styles.headerTitle}>
              {selectedDate.format('MMMM YYYY')}
            </Text>
          </TouchableOpacity>
        }
        onPressArrowLeft={() => handleNavigateMonth('prev')}
        onPressArrowRight={() => handleNavigateMonth('next')}
        current={selectedDate.format('YYYY-MM-DD')}
        onDayPress={handleOnDayPress}
        markedDates={markedDates}
        style={styles.calendar}
        theme={{
          contentStyle: styles.contentStyle,
          // @ts-ignore
          'stylesheet.calendar.header': {
            header: styles.navigationHeader,
          },
          arrowStyle: { padding: 0 },
          calendarBackground: 'transparent',
          selectedDayBackgroundColor: theme?.colors?.primary ?? COLORS.COLOR_BLUE,
          textDayFontWeight: '400',
          textDayFontSize: 14,
          textSectionTitleColor: theme?.colors?.secondary ?? COLORS.COLOR_GRAY,
          todayTextColor: theme?.colors?.text ?? COLORS.COLOR_BLACK,
          dayTextColor: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
          textDisabledColor: theme?.colors?.disabled ?? COLORS.COLOR_GRAY_PLACEHOLDER,
          monthTextColor: theme?.colors?.text ?? COLORS.COLOR_LIGHTBLACK,
          textMonthFontWeight: '700',
          textMonthFontSize: 14,
          selectedDayTextColor: theme?.colors?.hover ?? COLORS.COLOR_HIGHLIGHTBLUE,
          ...calendarTheme,
        }}
        {...props}
      />
    </View>
  );
};
