import React, { useEffect, useRef, useCallback } from 'react'
import { Animated, Easing, View } from 'react-native'
import { useTheme } from '../../ThemeProvider/ThemeProvider'
import {
    ProgressIndicatorCircularProps,
    BaseProgressIndicatorProps,
} from '../interface'
import { createStyles } from '../style'

const ProgressIndicatorCircular: React.FC<ProgressIndicatorCircularProps & BaseProgressIndicatorProps> = (props) => {
    const { theme } = useTheme()
    const styles = createStyles(theme)
    const spinValue = useRef(new Animated.Value(0)).current

    const {
        progressIndicatorDarkTheme,
        progressDarkThemeMainContainer,
        progressMainContainer,
        progressDarkSpinner,
        progressSpinner,
        testID,
    } = props

    const spin = useCallback(() => {
        spinValue.setValue(0)
        Animated.timing(spinValue, {
            toValue: 1,
            duration: 1000,
            easing: Easing.linear,
            useNativeDriver: true,
        }).start(() => spin())
    }, [spinValue])

    useEffect(() => {
        spin()
    }, [spin])

    const spinInterpolate = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    })

    const progressDarkThemeMainContainerStyle = progressIndicatorDarkTheme
        ? [
              styles.progressDarkThemeMainContainer,
              progressDarkThemeMainContainer,
          ]
        : [styles.progressMainContainer, progressMainContainer]

    const progressDarkSpinnerStyle = progressIndicatorDarkTheme
        ? [styles.progressDarkSpinner, progressDarkSpinner]
        : [styles.progressSpinner, progressSpinner]

    return (
        <View style={progressDarkThemeMainContainerStyle} testID={testID}>
            <Animated.View
                style={[
                    progressDarkSpinnerStyle,
                    { transform: [{ rotate: spinInterpolate }] },
                ]}
            />
        </View>
    )
}

export default React.memo(ProgressIndicatorCircular)

