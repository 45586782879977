import React, { useRef, useCallback } from 'react'
import { Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native'
import { ButtonProps } from './types'
import { Platform } from 'react-native'
import { pickSingle } from 'react-native-document-picker'
import LinearGradientComponent from '../LinearGradient'
import { FileUploaderWeb } from './FileUploaderWeb'
import { ButtonBase } from './ButtonBase'
import COLORS from '../../utils/colors'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { createStyles } from './styles'

const DEFAULT_GRADIENT_COLORS = [
    COLORS.COLOR_LIGHTBLUE,
    COLORS.COLOR_MEDIUMBLUE,
] as [string, string]

const Button = (props: ButtonProps) => {
    const {
        input,
        onPress,
        testID,
        gradientColors,
        disabled,
        style,
        ...baseProps
    } = props

    const { theme } = useTheme()
    const styles = createStyles(theme)

    const inputRef = useRef<HTMLInputElement | null>(null)
    const withGradient = !disabled && gradientColors?.length === 2
    const isDisabled = disabled || baseProps.loading
    const useFileUploaderWeb = Platform.OS === 'web' && input?.type === 'file'
    const pressableTestID = testID
        ? `${testID}-buttonPressable`
        : 'buttonPressable'

    const flexDirection =
        baseProps.iconPosition === 'left' ? 'row-reverse' : 'row'

    const combinedStyles = [
        styles.button,
        disabled && styles.disable,
        style,
        { flexDirection },
    ] as StyleProp<ViewStyle>

    const _onPress = useCallback(async () => {
        if (input?.type !== 'file') {
            return onPress?.()
        }
        if (Platform.OS == 'ios' || Platform.OS == 'android') {
            const res = await pickSingle({
                type: [input.accepts || '*'],
            })
            input?.onFileChange?.(res?.uri)
            return
        }
        inputRef?.current?.click()
    }, [input, inputRef, onPress])

    return withGradient ? (
        <Pressable
            testID={pressableTestID}
            disabled={isDisabled}
            onPress={_onPress}
            style={combinedStyles}
        >
            <LinearGradientComponent
                style={styles.gradient}
                colors={gradientColors || DEFAULT_GRADIENT_COLORS}
            >
                {useFileUploaderWeb && (
                    <FileUploaderWeb
                        testID={testID}
                        input={input}
                        inputRef={inputRef}
                    />
                )}
                <ButtonBase {...baseProps} />
            </LinearGradientComponent>
        </Pressable>
    ) : (
        <Pressable
            testID={pressableTestID}
            disabled={isDisabled}
            onPress={_onPress}
            style={combinedStyles}
        >
            {useFileUploaderWeb && (
                <FileUploaderWeb
                    testID={testID}
                    input={input}
                    inputRef={inputRef}
                />
            )}
            <ButtonBase {...baseProps} />
        </Pressable>
    )
}

export default Button
